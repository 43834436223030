<template>
   <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link class="crumbs_item" tag="a" to="/admin/PromotionManage/couponManage">优惠券管理 / </router-link>
          <span class="crumbs_item crumbs_last">新建兑换券</span>
        </span>
      </div>
    </div>

    <div class="title-blod">基本信息</div>

    <div class="all_content_box">
      <div class="L_R_inner">
        <span class="all_left_name">兑换券名称：</span>
        <div class="right_Div">
          <a-input class="all_input" placeholder="如卡罗兰纳优惠券，最多10个字" />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name">使用时间：</span>
        <div class="right_Div">
          <a-date-picker format="YYYY-MM-DD HH:mm:ss" placeholder="选择开始时间" :disabled-date="disabledDate"
            :disabled-time="disabledDateTime" :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }" />
        </div>
        <div class="right_Div">
          至
        </div>
        <div class="right_Div">
          <a-date-picker format="YYYY-MM-DD HH:mm:ss" placeholder="选择结束时间" :disabled-date="disabledDate"
            :disabled-time="disabledDateTime" :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }" />
        </div>
        <div class="right_Div">
          <a-radio>不限</a-radio>
        </div>
      </div>
    </div>

    <!-- <div class="title-blod">兑换设置</div>

    <div class="all_content_box">
      <div class="L_R_inner">
        <span class="all_left_name">兑换商品：</span>
        <div class="right-box">
          <div class="box-first">
            <div class="right_Div">
              <a-button type="primary" class="btn" @click="relationFilling">新建</a-button>
            </div>
          </div>
        </div>
      </div>
      <a-table class="table-template" rowKey="key" :columns="columns" :data-source="tableData" :pagination="{
                total: total,
                showTotal: (res) => `共${total}条`,
                showQuickJumper: true,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '50', '100'],
              }">
        <template slot="index" slot-scope="item, row, index">
          {{index + 1}}
        </template>

        <template slot="price" slot-scope="item">
          {{item + '元'}}
        </template>

        <template slot="operation">
          <span class="blueText">
            <span>移除</span>
          </span>
        </template>
      </a-table>
      <div class="amount">所选商品：￥200元</div>

    </div> -->

    <div class="button">
      <a-button type="primary" class="btn">确认创建</a-button>
      <a-button class="all_boder_btn btn">取消</a-button>
    </div>

    <a-modal v-model="relation" width='688px' title="选择商品" :centered='true' :closable='false'>
      <div class="search-box">
        <a-select class="search-input" placeholder="请选择商品类型" @change="handleChange">
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value="lucy">
            行大运
          </a-select-option>
          <a-select-option value="disabled" disabled>
            暴富
          </a-select-option>
        </a-select>
        <a-input class="search-input" placeholder="请输入商品名称" />
        <a-button class="search-btn" type="primary">搜索</a-button>
      </div>

      <div class="table-box">
        <a-table class="table-template" :row-selection="{
       columnTitle:'选择'
      }" rowKey="key" :columns="popupcolumns" :data-source="popupData" :pagination="{
        total: ptotal,
        showTotal: (res) => `共${ptotal}条`,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50', '100'],
      }" bordered>

          <template slot="index" slot-scope="item, row, index">
            <!-- {{ (queryParams.pageNum - 1) * queryParams.pageSize + index + 1 }} -->
            {{index + 1}}
          </template>

        </a-table>

        <a-table class="table-template" rowKey="key" :columns="selectcolumns" :data-source="selectData" :pagination="{
        total: ptotal,
        showTotal: (res) => `共${ptotal}条`,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50', '100'],
      }">

          <template slot="num">
            当前已选中 <span class="blueText">{{3}}</span> 个商品
          </template>

          <template slot="operation">
            <span class="blueText">清除</span>
          </template>

        </a-table>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="relationOk">确认</a-button>
        <a-button @click="relationCancel">取消</a-button>
      </template>

    </a-modal>
  </div>
</template>

<script>
  import moment from "moment";
  const popupcolumns = [{
      title: "序号",
      align: "center",
      dataIndex: "index",
      scopedSlots: {
        customRender: "index",
      },
    },
    {
      title: "商品名称",
      align: "center",
      dataIndex: "info",
    },
  ];
  const selectcolumns = [{
      align: "center",
      dataIndex: "info",
      width: 120,
      colSpan: 2,
      scopedSlots: {
        customRender: "info",
        title: "num",
      },
    },
    {
      title: "操作",
      align: "center",
      colSpan: 0,
      width: 20,
      dataIndex: "operation",
      scopedSlots: {
        customRender: "operation",
      },
    },
  ];
  const popupData = [{
      key: "1",
      info: "康复医疗",
      photo: 32,
    },
    {
      key: "2",
      info: "康复医疗",
      photo: 42,
    },
    {
      key: "3",
      info: "康复医疗",
      photo: 32,
    },
  ];
  const selectData = [{
      key: "1",
      info: "康复医疗",
    },
    {
      key: "2",
      info: "康复医疗",
    },
    {
      key: "3",
      info: "康复医疗",
    },
  ];
  const columns = [{
      title: "序号",
      align: "center",
      dataIndex: "index",
      scopedSlots: {
        customRender: "index",
      },
    },
    {
      title: "商品名称",
      align: "center",
      dataIndex: "name",
      scopedSlots: {
        customRender: "name"
      },
    },
    {
      title: "单价",
      align: "center",
      dataIndex: "price",
      scopedSlots: {
        customRender: "price"
      },
    },
    {
      title: "商品类型",
      align: "center",
      dataIndex: "classify",
    },
    {
      title: "商品库存",
      align: "center",
      dataIndex: "stock",
    },
    {
      title: "操作",
      align: "center",
      dataIndex: "operation",
      scopedSlots: {
        customRender: "operation"
      },
    },
  ];
  const tableData = [{
      key: "1",
      name: "康复医疗",
      num: 32,
      time: "2022/12/24",
    },
    {
      key: "2",
      name: "康复医疗",
      num: 42,
      time: "2022/12/24",
    },
    {
      key: "3",
      name: "康复医疗",
      num: 32,
      time: "2022/12/24",
    },
    {
      key: "4",
      name: "康复医疗",
      num: 99,
      time: "2022/12/24",
    },
  ];
  export default {
    // 可用组件的哈希表
    components: {},
    // 接收传值
    props: {},
    // 数据对象
    data() {
      return {
        columns,
        tableData,
        total: 0,
        pageNumber: 1, //页码
        pageSize: 10, // 页数
        relation: false,
        selectcolumns,
        selectData,
        popupcolumns,
        popupData,
        ptotal: 0,
        ppageNumber: 1, //页码
        ppageSize: 10, // 页数
      };
    },
    // 事件处理器
    methods: {
      moment,
      handleChange(value) {
        // console.log(`selected ${value}`);
      },

      range(start, end) {
        const result = [];
        for (let i = start; i < end; i++) {
          result.push(i);
        }
        return result;
      },

      disabledDate(current) {
        // Can not select days before today and today
        return current && current < moment().endOf("day");
      },

      disabledDateTime() {
        return {
          disabledHours: () => this.range(0, 24).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        };
      },
      relationOk(e) {
        this.relation = false;
      },
      relationCancel(e) {
        this.relation = false;
      },

      relationFilling() {
        this.relation = true;
      },
    },
    // 生命周期-实例创建完成后调用
    created() {},
    // 生命周期-实例挂载后调用
    mounted() {},
    // 生命周期-实例销毁离开后调用
    destroyed() {},
    // 计算属性监听
    computed: {},
    // 自定义的侦听器
    watch: {},
  };

</script>

<style lang="less" scoped>
.all_main_wrap {
  a {
    color: #333333;
  }

  .title-blod {
    margin-top: 16px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 16px;
  }

  .all_content_box {
    .L_R_inner {
      margin: 24px;
      .all_left_name {
        width: 100px;
        text-align: right;
      }

      .right_Div {
        .all_input {
          width: 337px;
        }
        /deep/.ant-input {
          height: 37px;
        }
        .else_input {
          margin-right: 12px;
          width: 140px;
        }
        .left {
          margin-left: 12px;
        }
      }

      .right-box {
        .box-first {
          width: 471px;
          .right_Div {
            display: inline-block;
            line-height: 37px;
            .ant-select {
              width: 166px;
            }
          }
        }
        .Up_Down_inner {
          margin-left: 20px;
          .all_left_name {
            display: flex;
            align-items: center;
            margin-top: 24px;
            .btn {
              margin: 0px 24px;
            }
          }
        }
      }
    }

    .amount {
      font-size: 16px;
      color: #eb1411;
      margin: 0 0 30px 24px;
    }
  }

  .button {
    .btn {
      margin-left: 20px;
    }
  }
}
/deep/.ant-modal-body {
  overflow: hidden;
}
/deep/.ant-modal-title {
  text-align: center;
  color: #000000;
}
/deep/.ant-modal-header {
  border-bottom: none;
}
/deep/.ant-modal-footer {
  text-align: center;
  border-top: none;
}
/deep/ .ant-modal-body {
  padding: 14px 36px 0px;
}
.table-template {
  /deep/.ant-table {
    border: none;
  }
}
.table-box {
  display: flex;
  justify-content: space-between;
}

.search-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .search-input {
    width: 36%;
  }
}
.picture {
  width: 60px;
}
</style>
